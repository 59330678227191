








import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
const layoutImagesStore = getModule(layoutImagesVuexModule);


export default Vue.extend ({
    props: {
        pageAccessor: {
            type: String,
            default: ""
        }
    },

    computed: {
        layoutImagePagePrimary(): string {
            if (layoutImagesStore.getLayoutImage(this.pageAccessor)) {
                return layoutImagesStore.getLayoutImage(this.pageAccessor);
            }
            return layoutImagesStore.getLayoutImage("pagePrimary");
        },
    
        bgImageStyle(): string {
            if (this.layoutImagePagePrimary) {
                return "background-image:url(" + this.layoutImagePagePrimary + ")";
            } else {
                return "";
            }
        }
    }

})
