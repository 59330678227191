























































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";

import { TradeshowCompanyObject } from "@/types/interfaces";


const tradeshowStore = getModule(tradeshowVuexModule);

export default Vue.extend ({
    data() {
        return {
             isLoading : true,

        }
    },
    components: {
        Spinners,
        "page-title-header": PageTitleHeader
    },
    filters: {
        maxCharacter(value: string) {
            return value.slice(0, 250) + "...";
        }
    },
    computed: {

        titleCompanies(): Array<TradeshowCompanyObject> {
            const companies = tradeshowStore.tradeshowCompanies.title;
            return Vue.prototype.MgSortByProperty(companies, "companyName");
        },
        premierCompanies(): Array<TradeshowCompanyObject> {
            const companies = tradeshowStore.tradeshowCompanies.premier;
            return Vue.prototype.MgSortByProperty(companies, "companyName");
        },

        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },
        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("tradeshow");
        },

        pageDecorImage(): string {
            let returnValue = "";
            if (
                this.pageConfig.decorImages &&
                this.pageConfig.decorImages.pageBackground
            ) {
                returnValue = `/bg/${this.pageConfig.decorImages.pageBackground}`;
            }
            return returnValue;
        }
    },

    async created() {
        await tradeshowStore
            .getTradeshowCompanies()
            .finally(() => (this.isLoading = false));
    }
})
